<template>
  <el-dialog title="重置密码" :visible="formVisible" width="600px" @close="resetForm('ruleForm')">
    <el-form
      style="margin-top: 20px;margin-bottom:20px;"
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="90px"
      class="demo-ruleForm"
      label-position="left"
    >
      <el-form-item label="原密码" prop="oldpass">
        <el-input v-model="ruleForm.oldpass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm('ruleForm')">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '../api/base';
export const resetPassword = api('')('user.reset.password.json');
const passwordReg = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;
export default {
  props: {
    formVisible: Boolean
  },
  data () {
    var pass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入新密码'));
      }
      if (!passwordReg.test(value)) {
        callback(
          new Error(
            '密码强度规则，最少6位，包括至少1个大写字母，1个小写字母，1个数字'
          )
        );
      }
      if (this.ruleForm.checkPass !== '') {
        this.$refs.ruleForm.validateField('checkPass');
      }
      callback();
    };
    var checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入新密码'));
      }
      if (value !== this.ruleForm.pass) {
        return callback(new Error('两次密码输入不一致！'));
      }
      callback();
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: ''
      },
      rules: {
        oldpass: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        pass: [
          {
            required: true,
            validator: pass,
            trigger: 'change'
          }
        ],
        checkPass: [
          {
            required: true,
            validator: checkPass,
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.$message.success('密码修改成功');
          await resetPassword({
            username: window.localStorage.getItem('username'),
            oldpassword: this.ruleForm.oldpass,
            newpassword: this.ruleForm.pass
          });
          const localStorages = window.localStorage;
          Object.keys(localStorages).forEach(it => {
            const reg = /^custom_/;
            if (!reg.test(it)) {
              window.localStorage.removeItem(it);
            }
          });
          this.$router.push({
            path: '/login'
          });
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.$emit('update:formVisible', false);
    }
  }
};
</script>
